import React, { useState } from "react";
import logo from "../images/everidoor.jpg";
import { Link, Navigate } from "react-router-dom";
import {
  BiLogoInstagram,
  BiLogoLinkedinSquare,
  BiPhone,
  BiSolidPhone,
} from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "@formspree/react";
import axios from "axios";

const Footer = () => {
  const [formData, setFormData] = useState({
    email: "",
    checked: false,
  });
  // console.log("data form---------->>>>", formData);
  const [response, setResponse] = useState("");
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "https://qdp72jc1-4000.inc1.devtunnels.ms/newsletter",
        {
          email: formData.email,
          value: formData.checked,
        }
      );
      setResponse(res.data.message);
    } catch (err) {
      console.log(err);
    }
  };

  const navigate = useNavigate();
  return (
    <div className="w-full h-full flex flex-col  pb-6 lg:px-24 px-3 ">
      <img
        src={logo}
        alt="everidoor"
        onClick={() => navigate("/")}
        className="md:h-[20px] cursor-pointer  w-[100px] md:w-[126px] mt-3 h-[17px]"
      />
      <div className="md:flex  md:gap-3 justify-between  pt-4">
        <div className="md:w-[550px]  md:mr-4 lg:mr-0  ">
          <p className="text-[#000000] md:text-[14px] pr-8 text-[12px] font-light leading-42 tracking-wider  md:mx-0 mt-4 md:mt-12 text-left">
            Keep yourself connected to the forefront of luxury advertising and
            partnership.
          </p>
          <h1 className="Ramillas text-black text-[25px] font-medium leading-42 tracking-wider ">
            Subscribe to our newsletter.
          </h1>

          <div>
            <form
              className="flex gap-4  lg:w-[100%] w-full items-center"
              action=""
              method="POST"
              onSubmit={handleSubscribe}
            >
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your email..."
                className="md:w-[292px]  h-[34px]  py-2 border-[#C5C5C5] px-4 mt-4 border-2 text-[12px]  focus:outline-none text-gray-600"
                required
                value={formData.email}
                onChange={handleInputChange}
              />
              <button
                type="submit"
                className="border-2 border-solid border-[#B48B05] text-[#B48B05] text-[12px] w-[150px] h-[34px] mt-4"
              >
                {response ? "SUBSCRIBED" : "SUBSCRIBE"}
              </button>
            </form>

            <div className="flex items-center   md:justify-start  ">
              <p className={`text-lg text-[#B48B05] ${response ? "pt-6" : ""}`}>
                {response}{" "}
              </p>
            </div>
          </div>
          {!response && (
            <div className="mt-4 flex gap-2 items-center">
              <input
                onChange={handleCheckboxChange}
                type="checkbox"
                name="checked"
                id="checkbox"
                className="h-4 w-4 cursor-pointer mt-2 border-gray-400"
                required
              />
              <p className="cursor-pointer text-gray-400 font-light text-[12px] mt-2">
                I would like to receive updates from everidoor via email
              </p>
            </div>
          )}
          {/* <div className="mt-8 flex gap-2">
                        <a
                            href="https://www.instagram.com/everidoor/?hl=en"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <BiLogoInstagram size={28} />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/everidoor/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <BiLogoLinkedinSquare size={28} />
                        </a>
                        <a href="Contact">
                            <BiPhone size={28} />
                        </a>
                    </div> */}
        </div>
        <div className="grid md:grid-cols-3  grid-cols-2 md:gap-20  gap-8 md:mt-0 mt-8">
          <div className="mt-9">
            {/* <p className="text-[#1B1B1B] Ramillas  md:text-[1rem] text-[1rem] font-[700] leading-[33px] tracking-[0.9px]  transition duration-300 ease-in-out">
              Company
            </p> */}
            
            <Link to="/About">
              <p className="text-[#424242] font-light md:text-[1rem] text-[14px] hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                About Us
              </p>
            </Link>
            <Link to="/Contact">
              <p className="text-[#424242] font-light md:text-[1rem] text-[14px] hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                Contact Us
              </p>
            </Link>
            <Link to="/Location">
              <p className="text-[#424242] md:text-[1rem] font-light text-[14px] hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                Locations
              </p>
            </Link>
            <Link to="/Advertiser">
              <p className="text-[#424242] md:text-[1rem] font-light text-[14px] hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                Advertiser
              </p>
            </Link>
            <Link to="/Outlook2023">
              <p className="text-[#424242] font-light md:text-[1rem] text-[14px] hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                Luxury Outlook 2025
              </p>
            </Link>
            {/* <Link to="/Investor">
              <p className="text-[#424242] font-light md:text-[1rem] text-[14px] hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                Investors
              </p>
            </Link> */}
            
            {/* <a href="/Press">
                            <p className="text-[#424242] font-light md:text-[1rem] text-[14px] hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                                Press
                            </p>
                        </a>
                        <p className="text-[#424242] md:text-[1rem] font-light  text-[14px] hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                            Join Us
                        </p>
                        <a href="/Insights">
                            <p className="text-[#424242] md:text-[1rem] font-light text-[14px] hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                                Insights
                            </p>
                        </a> */}
            
          </div>
          <div className="mt-9 ">
            <Link to="/SocialResponsibility">
              <p className="text-[#424242] md:text-[1rem] font-light text-[14px] hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                Sustainability
              </p>
            </Link>
            <Link to="/Policy">
              <p className="text-[#424242] md:text-[1rem] font-light text-[14px] hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                Privacy Policy
              </p>
            </Link>
            <Link to="/TermandCondition">
              <p className="text-[#424242] md:text-[1rem] font-light text-[14px] hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                Terms & Conditions
              </p>
            </Link>
            {/* <Link to="/SocialResponsibility">
              <p className="text-[#424242] md:text-[1rem] font-light text-[14px] hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                Sustainability
              </p>
            </Link> */}
            {/* <p className="text-[#424242] md:text-[1rem] font-light text-[14px]  hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                            Advertiser's client advisory
                        </p> */}
          </div>
          {/* <div className="">
            <Link to="/Partner">
              <p className="text-[#1B1B1B] Ramillas font-[700] md:text-[1rem] text-[1rem]  leading-[33px] tracking-[0.9px] hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                Display Network
              </p>
            </Link>
            <p className="text-[#424242] md:text-[1rem] font-light  text-[14px] mt-4 hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
              {/* DP's client advisory */}
            {/* </p>
            <Link to="/Location">
              <p className="text-[#424242] md:text-[1rem] font-light text-[14px] hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                Locations
              </p>
            </Link>
          </div> */}
        </div>
      </div>

      <div className="lg:flex items-center justify-between mt-[30px]">
        <div>
          <div className="flex lg:mb-6 md:mb-3 mb-10 gap-2">
            <a
              href="https://www.instagram.com/everidoor/?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              <BiLogoInstagram size={"32px"} />
            </a>
            <a
              href="https://www.linkedin.com/company/everidoor/"
              target="_blank"
              rel="noreferrer"
            >
              <BiLogoLinkedinSquare size={"32px"} />
            </a>
            <a href="Contact">
              <BiSolidPhone size={"32px"} />
            </a>
          </div>
          <p className="md:text-left  text-center text-sm text-[#000000] md:mb-0 mb-4">
            Copyright © 2025 everidoor. All Rights Reserved.
          </p>
        </div>
        {/* <p className="text-[#000000]   font-light  lg:mt-14 md:text-[1rem] text-[12px] text-center md:text-left">
          <Link to={"/Policy"} className=" cursor-pointer">
            PRIVACY & POLICY
          </Link>{" "}
          &nbsp;&nbsp;| &nbsp;{" "}
          <Link to={"/TermandCondition"}>TERMS & CONDITIONS </Link>
        </p> */}
      </div>
    </div>
  );
};

export default Footer;
